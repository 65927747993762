<template>
<div class="container">
    <div class="box">
        <h1 class="title is-3 has-text-info">Subidor de preguntas para prueba parcial</h1>
        <div class="columns">
            <div class="column is-5">
                <div class="box has-background-light">
                    <div class="columns">
                        <div class="column is-6">
                            <h1 class="title is-5 has-text-info">Asignaturas</h1>
                        </div>
                        <div class="column is-6">
                            <b-field label="Seleccione">
                                <b-select v-model="asignatura" expanded placeholder="Selecciona una asignatura">
                                    <option v-for="asignatura in asignaturas" :value="asignatura.id" :key="asignatura.id">
                                        {{ asignatura.nombre }}
                                    </option>
                                </b-select>
                            </b-field>
                        </div>
                    </div>
                    <div class="columns">
                        <div class="column is-6">
                            <h1 class="title is-5 has-text-info">Niveles</h1>
                        </div>
                        <div class="column is-6">
                            <b-field label="Seleccione">
                                <b-select v-model="nivel" expanded placeholder="Selecciona un nivel">
                                    <option v-for="nivel in niveles" :value="nivel.id" :key="nivel.id">
                                        {{ nivel.nombre }}
                                    </option>
                                </b-select>
                            </b-field>
                        </div>
                    </div>
                    <div class="columns">
                        <div class="column is-5">
                            <h1 class="title is-5 has-text-info">Listado IDs</h1>
                        </div>
                        <div class="column is-4">
                            <section>
                                <b-upload v-model="file" class="file-label">
                                                <span class="file-cta">
                                                    <span class="file-label">Seleccione</span>
                                                </span>
                                                <span class="file-name" v-if="file">
                                                    {{ file.name }}
                                                </span>
                                </b-upload>
                            </section>
                        </div>
                        <div class="column is-3">
                            <b-button type="is-primary" class="ml-2" icon-left="fas fa-upload" icon-pack="fas" @click="carga" >Cargar</b-button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="column is-5">
                <div class="box has-background-light">
                    <div class="columns">
                        <div class="column is-6">
                                <h1 class="title is-5 has-text-info">Corregir preguntas sin respuestas</h1>
                        </div>
                    </div>
                    <div class="columns">
                        <div class="column is-6">
                            <h1 class="title is-5 has-text-info">ID prueba nueva</h1>
                        </div>
                        <div class="column is-6">
                            <b-field label="Ingrese id prueba">
                                <b-input v-model="fix_prueba_nueva"></b-input>
                            </b-field>
                        </div>
                    </div>
                    <div class="columns">
                        <div class="column is-6">
                            <h1 class="title is-5 has-text-info">Numero de pregunta sin respuestas</h1>
                        </div>
                        <div class="column is-6">
                            <b-field label="Ingrese numero de pregunta">
                                <b-input v-model="fix_pregunta_sin_respuesta"></b-input>
                            </b-field>
                        </div>
                    </div>
                    <div class="columns">
                         <div class="column is-6">
                        </div>
                        <div class="column is-3">
                            <b-button type="is-primary" class="ml-2" icon-left="fas fa-wrench" icon-pack="fas" @click="fix" >Corregir</b-button>
                        </div>
                    </div>
                    <div class="columns">
                        <div class="column is-3">
                        </div>
                        <div class="column is-9">
                            <h1 class="subtitle is-12 has-text-info">{{mensaje}}</h1>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>
<script>

import axios from 'axios'

export default {
    name: "ParcialArray",
    metaInfo: {
        title: 'Aplicar evaluación'
    },
    data() {
        return {
            datos: [],
            ides: [],
            asignaturas: [],
            niveles:[],
            nivel:null,
            asignatura:null,
            file:null,
            fix_prueba_nueva:null,
            fix_pregunta_sin_respuesta:null,
            mensaje:null

        }
    },
    methods:{
        getAsignaturas() {
            axios.get('/api/asignaturas/select').then((res) => {
                this.asignaturas = res.data;
            }).catch((err) => {
                throw err
            })
        },
        getNiveles() {
            axios.get('/api/niveles/get').then((res) => {
                this.niveles = res.data;
            }).catch((err) => {
                throw err
            })
        },
        fix(){
            let formData = new FormData();
            formData.append('id_prueba',this.fix_prueba_nueva);
            formData.append('numero_pregunta', this.fix_pregunta_sin_respuesta);
            //console.log('Prueba: '+this.fix_prueba_nueva);
            //console.log('Orden_pregunta: '+this.fix_pregunta_sin_respuesta);
            axios.post('api/preguntas/fix_pregunta_parcialarray',formData).then((res)=>{
                console.log(res.data);
                if(res.data.mensaje){
                    this.mensaje = res.data.mensaje;
                }else{
                    this.mensaje = "Se han agregado las respuestas";
                }
            })
        },
        carga(){
            let formData = new FormData();
            this.estado=null;
            formData.append('file', this.file);
            formData.append('asignatura',this.asignatura);
            formData.append('nivel',this.nivel);

            
            axios.post('api/preguntas/parcialarray',formData).then((res)=>{
                
                console.log(res.data);
        })}
    },
    mounted(){
        this.getAsignaturas();
        this.getNiveles();
    }
}
</script>
