<template>
<div class="container" style="margin-top:-35px">
    <div class="box">
        <div class="columns">
            <div class="column">
                <nav class="breadcrumb" aria-label="breadcrumbs">
                    <ul>
                        <li>
                            <router-link to="/">Home</router-link>
                        </li>
                        <li class="is-active"><a href="#" aria-current="page">Revisar alumnos</a></li>
                    </ul>
                </nav>
            </div>
        </div>
    </div>
    <div class="box">
        <div class="columns">
            <div class="column">
                <h1 class="title">Intrucciones</h1>
                <p>
                    - Aqui podras tomar una lista de alumnos y cargarla al sistema,  el sistema lo que hara con la lista es verificar que es los alumnos existan luego verificara si esos alumnos estan en el curso que salga en la nueva lista si se encuentran en un curso distinto los cambiara a nuevo curso siempre y cuando exista el curso!
                </p>
                <p> - PASO 1 : Seleccioan un archivo excel para cargar </p>
                <p> - PASO 2 : dale al boton "Verificar" </p>
                <p> - PASO 3 : Esperar a que la tabla de abajo aparesca y ya estara lista la operacion  </p>
                <p> - PASO 2 : Ver la tabla para comprobar que todos los alumnos estes con el estado en cargado (verde)  </p>
                <p> - RECOMENDACION: si encuentras alumnos con error de carga crear una tabla aparte con los alumnos que no se cargaron y subir dichos alumnos  </p>
                <p> - ya estara listo ahora Revisar si todo esta bien en la lista de alumnos</p>
            </div>
        </div>
    </div>
    <div class="box">
        <div class="columns">                         
             <div class="columns">  
                 <div class="column is-4" >
                    <b-button class="is-info"  @click="verifica_alumnos()" :loading="loading">
                        Verificar 
                    </b-button>
                </div>
                <div class="column">                   
                    <b-upload v-model="file" class="file-label">
                        <span class="file-cta">
                            <span class="file-label">Seleccione archivo</span>
                        </span>
                        <span class="file-name" v-if="file">
                            {{ file.name }}
                        </span>
                    </b-upload>
                </div>
            </div>    
            
           
        </div>
    </div>
    <div class="columns" v-show=" preguntas.length >= 1">
        <div class="column">
            <div class="box">
                <article class="message is-info">
                    <section class="message-body">
                        <h1 class="title">Cargados con exito = {{exito}}</h1> 
                    </section>
                </article>
            </div>
        </div>
        <div class="column">
            <div class="box">
                <article class="message is-danger">
                    <section class="message-body">
                        <h1 class="title">Error en la carga = {{error}}</h1>
                    </section>
                </article>
            </div>
        </div>
    </div>

     <div class="box">
        <table class="table table-success  table-striped">
            <thead>
                <tr>
                    <th scope="col">Nombre</th>
                    <th scope="col">Curso</th>
                    <th scope="col">Run</th>
                    <th scope="col">Estado</th>
                    <th scope="col">Detalle</th>
                </tr>
            </thead>
            <tbody>
             
                    <tr  v-for="pregunta in preguntas" v-bind:key="pregunta.id">
                        <th >{{pregunta.fila.nombres}} {{pregunta.fila.apellido_materno}} {{pregunta.fila.apellido_paterno}}</th>
                        <td >{{pregunta.fila.curso}}</td>
                        <td >{{pregunta.fila.run}} - {{pregunta.fila.dv}}</td>
                        <td v-if="pregunta.status == 'true'"> <b-tag type="is-success">Exito</b-tag></td>
                        <td v-else-if="pregunta.status == 'false'"><b-tag type="is-danger">Error</b-tag></td>
                        <td >{{pregunta.mensaje}}</td>
                        </tr>    
                                
               
            </tbody>
            </table>
            
    </div>
    
    
</div>
</template>
<script>
import axios from "axios";

export default {
    name: "EvaluacionesMisEvaluaciones",
    metaInfo: {
        title: 'Mis evaluaciones'
    },
    data() {
        return {
            
            id:'',
            prueba_id:'',
            preguntas: [],
            error: 0,
            exito: 0,
            loading: false,
            file:null
            
        }
    },
    methods: {
            verifica_alumnos() {
            let self = this;
            self.loading = true;

            let formData = new FormData();
            formData.append('file', this.file);
            axios.post('/api/alumnos/verificaralumnos', formData).then(response => {

                self.loading = false;
                this.preguntas = response.data.alumnos;
                this.error = response.data.error;
                this.exito = response.data.exito;

                if (response.data.status) {
                    this.$buefy.toast.open({
                        message: 'alumnos verificados',
                        type: 'is-success'
                    })
                    this.loadAsyncData();
                } else {
                    this.$buefy.toast.open({
                        message: 'ocurrio algun problema en la carga del documento ',
                        type: 'is-danger'
                    })
                }

            }).catch(error => {
                self.loading = false;
                throw error
            });
        },
      
        eliminar(id){ 
                 this.$buefy.dialog.confirm({
                title: 'Eliminar pregunta asignada',
                message: '¿Eliminaras la pregunta asigada all alumno estas segur@ ?',
                confirmText: 'si dale no mas ',
                type: 'is-danger',
                hasIcon: true,
                onConfirm: () => {
                    let params = {
                           id : id 
                    }
                    axios.post('/api/admin/eliminarRespuestaAsignada', params)
                        .then(response => {    
                             this.nolose = response.data;                       
                            this.$buefy.toast.open({
                                message: 'tamos ready se borro ',
                                type: 'is-success'
                            })
                           this.buscar();
                        })
                        .catch(error => {
                            this.$buefy.toast.open({
                                message: 'No se pudo cargar la respuestas',
                                position: 'is-bottom',
                                type: 'is-danger'
                            })
                            throw error
                        });
                }
             })
             
        }
        
      
      
    },
    mounted() {
        
    }
}
</script>
