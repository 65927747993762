<template>
<div class="container" style="margin-top:-35px">
    <div class="box">
        <div class="columns">
            <div class="column">
                <nav class="breadcrumb" aria-label="breadcrumbs">
                    <ul>
                        <li><router-link to="/">Home</router-link></li>
                        <li><router-link to="/usuarios">Usuarios</router-link></li>
                        <li class="is-active" v-if="id"><a href="#" aria-current="page">Editar</a></li>
                        <li class="is-active" v-else><a href="#" aria-current="page">Crear</a></li>
                    </ul>
                </nav>
            </div>
        </div>
    </div>

    <div class="box">
        <div class="columns">
            <div class="column">
                <h1 class="title">Usuarios</h1>
                <p>Completa el formulario para crear o editar un usuario</p>
            </div>
        </div>
            <div class="columns">
                <div class="column">
                    <b-field label="Roles">
                        <b-select v-model="rol" expanded placeholder="Selecciona un rol (opcional)">
                            <option value="">Ninguno</option>
                            <option value="2">Docente AEDUC</option>
                            <option value="3">Soporte AEDUC</option>
                        </b-select>
                    </b-field>
                    <b-field label="Nombres">
                        <b-input v-model="name" ref="name" placeholder="Ingrese nombres y apellidos"></b-input>
                    </b-field>
                    <b-field label="Rut">
                        <b-input v-model="rut" ref="rut" placeholder="Ingrese RUT del usuario" maxlength="11"></b-input>
                    </b-field>
                </div>
                <div class="column">
                    <b-field label="Email">
                        <b-input v-model="email" ref="email" placeholder="Ingrese email del usuario (o RUT si es estudiante)"></b-input>
                    </b-field>
                    <b-field label="Contraseña">
                        <b-input v-model="password" ref="password" type="password" password-reveal placeholder="Ingrese la contraseña del usuario"></b-input>
                    </b-field>
                    <b-field label="Fono">
                        <b-input v-model="phone" placeholder="Ingrese fono del usuario (opcional)" maxlength="9"></b-input>
                    </b-field>
                </div>
            </div>

            <div class="columns">
                <div class="column">
                    <b-button v-if="id" type="is-primary" @click="crear" :loading="loadingStore">Editar usuario</b-button>
                    <b-button v-else type="is-primary" @click="crear" :loading="loadingStore">Crear usuario</b-button>
                </div>
            </div>
    </div>
    <div class="box" v-show="id">
        <div class="columns">
            <div class="column">
                <h2 class="subtitle">Colegios</h2>
                <p>Selecciona los colegios que el usuario podrá ver</p>
            </div>
        </div>
        <div class="columns">
            <div class="column is-2">
                <b-field>
                    <b-select v-model="periodo" expanded placeholder="Selecciona un periodo">
                        <option value="2021">2021</option>
                    </b-select>
                </b-field>
            </div>
            <div class="column is-3">
                <b-field>
                    <b-input v-model="buscar_nombre" placeholder="Buscar nombre" type="search" icon="magnify">
                    </b-input>
                </b-field>
            </div>
            <div class="column is-3">
                <b-field>
                    <b-input placeholder="Buscar RBD" type="search" v-model="buscar_rbd" icon="magnify">
                    </b-input>
                </b-field>
            </div>
            <div class="column is-4">
                <b-button type="is-info" icon-left="magnify" @click="getColegios">Buscar</b-button>
            </div>
        </div>
        <div class="columns">
            <div class="column">
                <b-table :data="columns" :loading="loading" paginated backend-pagination :total="total" :per-page="perPage" @page-change="onPageChange" aria-next-label="Next page" aria-previous-label="Previous page" aria-page-label="Page"
                    aria-current-label="Current page" backend-sorting :default-sort-direction="defaultSortOrder" :default-sort="[sortField, sortOrder]" @sort="onSort">
                    <b-table-column field="id" label="ID" sortable v-slot="props">
                        {{ props.row.id }}
                    </b-table-column>
                    <b-table-column field="rbd" label="RBD" sortable v-slot="props">
                        {{ props.row.rbd }}
                    </b-table-column>
                    <b-table-column field="nombre" label="Nombre" sortable v-slot="props">
                        {{ props.row.nombre }}
                    </b-table-column>
                    <b-table-column label="Rol" v-slot="props">
                        <b-select v-model.lazy="props.row.rol" expanded placeholder="Selecciona un Rol" size="is-small">
                            <option value="4">Sostenedor</option>
                            <option value="5">UTP</option>
                            <option value="6">Docente</option>
                            <option value="7">Ayudante</option>
                            <option value="8">Estudiante</option>
                        </b-select>
                    </b-table-column>
                    <b-table-column label="Acciones" v-slot="props">
                        <b-button v-if="!props.row.check" size="is-small" @click="asignar(props.row.id)">Asignar</b-button>
                        <b-button v-else size="is-small" icon-left="check" type="is-success" outlined @click="desasignar(props.row.id)">Asignado</b-button>
                    </b-table-column>
                </b-table>
            </div>
        </div>
    </div>
</div>
</template>
<script>
import axios from "axios";
export default {
    data() {
        return {
            id: this.$route.params.id,
            rol: null,
            name: null,
            email: null,
            rut: null,
            phone: null,
            password: null,
            columns: [],
            checkboxGroup: [],
            total: 0,
            loading: false,
            sortField: 'id',
            sortOrder: 'desc',
            defaultSortOrder: 'desc',
            loadingStore: false,
            page: 1,
            perPage: 20,
            buscar_nombre: null,
            buscar_rbd: null,
            periodo : 2021
        }
    },
    methods: {
        desasignar(id){

            let params = {
                usuario_id: this.id,
                colegio_id: id,
                periodo: this.periodo
            };
            axios.post('/api/usuarios/desasignar/colegio', params).then(response => {
                this.$buefy.toast.open({
                    message: response.data.message,
                    type: 'is-success'
                })
                this.getColegios();
            }).catch(error => {
                this.$buefy.toast.open({
                    message: 'No se pudo desasignar el rol al colegio',
                    position: 'is-bottom',
                    type: 'is-danger'
                })
                throw error
            });
        },
        asignar(id){
            let self = this;
            let rol = this.columns.find(e => e.id === id).rol;
            if (!rol){
                this.$buefy.toast.open({
                    message: `Debes seleccionar un rol para asignar el colegio con ID ` + id,
                    position: 'is-bottom',
                    type: 'is-danger'
                })
                return
            }
            let params = {
                usuario_id: self.id,
                colegio_id: id,
                rol_id: rol,
                periodo: self.periodo
            };
            axios.post('/api/usuarios/asignar/colegio', params).then(response => {
                this.$buefy.toast.open({
                    message: response.data.message,
                    type: 'is-success'
                })
                this.getColegios();
            }).catch(error => {
                this.$buefy.toast.open({
                    message: 'No se pudo asignar el rol al colegio',
                    position: 'is-bottom',
                    type: 'is-danger'
                })
                throw error
            });
        },
        crear() {
            let self = this

            if (!this.email) {
                this.$buefy.toast.open({
                    message: `Debes ingresar un email`,
                    position: 'is-bottom',
                    type: 'is-danger'
                })
                this.$refs.email.focus();
                return
            }

            if (!this.name) {
                this.$buefy.toast.open({
                    message: `Debes ingresar los nombres`,
                    position: 'is-bottom',
                    type: 'is-danger'
                })
                this.$refs.name.focus();
                return
            }
            if (!this.id) {
                if (!this.password) {
                    this.$buefy.toast.open({
                        message: `Debes ingresar una contraseña`,
                        position: 'is-bottom',
                        type: 'is-danger'
                    })
                    this.$refs.password.focus();
                    return
                }
            }

            if (!this.rut) {
                this.$buefy.toast.open({
                    message: `Debes ingresar el RUT`,
                    position: 'is-bottom',
                    type: 'is-danger'
                })
                this.$refs.rut.focus();
                return
            }

            self.loadingStore = true;

            var params = {
                params: this.$data
            };

            let method = 'store';
            
            if (this.id) {
                method = 'update';
                
            }

            axios.post('/api/usuarios/' + method, params)
                .then(response => {
                    self.loadingStore = false;
                    if (response.data.status) {
                        this.$buefy.toast.open({
                            message: response.data.message,
                            type: 'is-success'
                        })
                    } else {
                        this.$buefy.toast.open({
                            message: response.data.message,
                            position: 'is-bottom',
                            type: 'is-danger'
                        })
                    }
                })
                .catch(error => {                   
                    let method = 'crear';
                    
                    if (this.id) {
                        method = 'actualizar';
                        
                    }
                    this.$buefy.toast.open({
                        message: 'No se pudo '+ method +' el usuario',
                        position: 'is-bottom',
                        type: 'is-danger'
                    })
                    self.loadingStore = false;
                    throw error
                });

        },
        getUsuario(){
            if (this.id) {
                let params = {
                    id: this.id
                }
                axios.post('/api/usuarios/get', params)
                    .then(response => {
                        let data = response.data

                        this.rol = data.rol_id
                        this.email = data.email
                        this.name = data.name
                        this.rut = data.rut
                        this.phone = data.phone
                    })
                    .catch(error => {
                        this.$buefy.toast.open({
                            message: 'No se pudo encontrar al usuario',
                            position: 'is-bottom',
                            type: 'is-danger'
                        })
                        throw error
                    });
            }
        },
        getColegios() {
            if (this.id) {
                const params = [
                    `sort_by=${this.sortField}`,
                    `sort_order=${this.sortOrder}`,
                    `page=${this.page}`,
                    `usuario=${this.id}`,
                    `buscar_nombre=${this.buscar_nombre}`,
                    `buscar_rbd=${this.buscar_rbd}`,
                    `periodo=${this.periodo}`
                ].join('&')

                this.loading = true;
                axios.get(`/api/colegios/list?${params}`)
                    .then(({
                        data
                    }) => {
                        this.columns = []
                        let currentTotal = data.total
                        if (data.total / this.perPage > 1000) {
                            currentTotal = this.perPage * 1000
                        }
                        this.total = currentTotal
                        data.data.forEach((item) => {
                            this.columns.push(item)
                        })
                        this.loading = false
                    }).catch((error) => {
                        this.columns = []
                        this.total = 0
                        this.loading = false
                        throw error
                    })
            }
        },
        /*
         * Handle page-change event
         */
        onPageChange(page) {
            this.page = page
            this.getColegios()
        },
        /*
         * Handle sort event
         */
        onSort(field, order) {
            this.sortField = field
            this.sortOrder = order
            this.getColegios()
        },
    },
    mounted() {
        this.getColegios()
        this.getUsuario()
    }
}
</script>
