<template>
<div class="container" style="margin-top:-35px">
    <div class="box">
        <div class="columns">
            <div class="column">
                <nav class="breadcrumb" aria-label="breadcrumbs">
                    <ul>
                        <li><router-link to="/">Home</router-link></li>                       
                    </ul>
                </nav>
            </div>
        </div>
    </div>
    <div class="box">
        <div class="columns">
            <div class="column">                   
                <b-upload v-model="file" class="file-label">
                    <span class="file-cta">
                        <span class="file-label">Seleccione archivo</span>
                    </span>
                    <span class="file-name" v-if="file">
                        {{ file.name }}
                    </span>
                </b-upload>
            </div>
            <div class="column">
                <b-button @click="carga()" :loading="loading">
                    Cargar 
                </b-button>
            </div>
        </div>
    </div>
</div>
</template>
<script>
import axios from "axios";
export default {
    data() {
        return {
            file:null
        }
    },
    methods: {
        carga(){
            let self = this
            self.loading = true;

            let formData = new FormData();
            formData.append('file', this.file);
            axios.post('/api/inyectar', formData).then(response => {

                self.loading = false;

                if (response.data.status) {
                    this.$buefy.toast.open({
                        message: response.data.message,
                        type: 'is-success'
                    })
                    this.loadAsyncData();
                } else {
                    this.$buefy.toast.open({
                        message: response.data.message,
                        type: 'is-danger'
                    })
                }

            }).catch(error => {
                self.loading = false;
                throw error
            });
        },
       
  
    },
  
}
</script>
