<template>
<div class="container" style="margin-top:-35px">
    <div class="box">
        <div class="columns">
            <div class="column">
                <nav class="breadcrumb" aria-label="breadcrumbs">
                    <ul>
                        <li><router-link to="/">Home</router-link></li>
                        <li class="is-active"><a href="#" aria-current="page">Colegios</a></li>
                    </ul>
                </nav>
            </div>
        </div>
    </div>

    <div class="box">
        <div class="columns">
            <div class="column">
                <h1 class="title">Colegios</h1>
                <p>Permite administrar colegios creados para utilizar el sistema.</p>
            </div>
        </div>

        <div class="columns">
            <div class="column is-3">
                <b-field>
                    <b-input v-model="buscar_nombre" placeholder="Buscar nombre" type="search" icon="magnify">
                    </b-input>
                </b-field>
            </div>
            <div class="column is-3">
                <b-field>
                    <b-input placeholder="Buscar RBD" type="search" v-model="buscar_rbd" icon="magnify">
                    </b-input>
                </b-field>
            </div>
            <div class="column is-4">
                <b-button type="is-info" icon-left="magnify" @click="loadAsyncData">Buscar</b-button>
            </div>
            <div class="column is-2">
                <b-button tag="router-link" to="/colegios/crear" type="is-primary" icon-left="fas fa-plus" icon-pack="fas">Crear colegio</b-button>
            </div>
        </div>
        <div class="columns">
            <div class="column">
                <section>
                    <b-table :data="data" :loading="loading" paginated backend-pagination :total="total" :per-page="perPage" @page-change="onPageChange" aria-next-label="Next page" aria-previous-label="Previous page" aria-page-label="Page"
                        aria-current-label="Current page" backend-sorting :default-sort-direction="defaultSortOrder" :default-sort="[sortField, sortOrder]" @sort="onSort">

                        <b-table-column field="id" label="ID" sortable v-slot="props">
                            {{ props.row.id }}
                        </b-table-column>

                        <b-table-column field="comuna_id" label="Comuna" sortable v-slot="props">
                            {{ props.row.comuna.nombre }}
                        </b-table-column>

                        <b-table-column field="nombre" label="Nombres" sortable v-slot="props">
                            {{ props.row.nombre }}
                        </b-table-column>

                        <b-table-column field="rbd" label="RBD" sortable v-slot="props">
                            {{ props.row.rbd }}
                        </b-table-column>

                        <b-table-column field="created_at" label="Creación" sortable v-slot="props">
                            {{ props.row.created_at ? new Date(props.row.created_at).toLocaleString('ES-CL') : 'unknown' }}
                        </b-table-column>

                        <b-table-column label="Acciones" v-slot="props">
                            <div class="buttons">
                                <b-button type="button" class="button is-small is-success is-outlined" tag="router-link" :to="'/cursos/' + props.row.id + '/'" name="button"> <i class="fas fa-graduation-cap"></i> Cursos</b-button>
                                <b-button type="button" class="button is-small is-info is-outlined" tag="router-link" :to="'/colegios/' + props.row.id + '/editar'" name="button"> <i class="fas fa-edit"></i> Editar</b-button>
                                <button @click="eliminar(props.row.id)" type="button" class="button is-small is-danger is-outlined" name="button"> <i class="fas fa-trash"></i> Eliminar</button>
                            </div>

                        </b-table-column>
                        <template slot="empty">
                            <section class="section">
                                <div class="content has-text-grey has-text-centered">
                                    <p>No se encontraron colegios</p>
                                </div>
                            </section>
                        </template>
                    </b-table>
                </section>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import axios from "axios";
export default {
    data() {
        return {
            data: [],
            total: 0,
            loading: false,
            sortField: 'id',
            sortOrder: 'desc',
            defaultSortOrder: 'desc',
            page: 1,
            perPage: 20,
            buscar_nombre: null,
            buscar_rbd: null
        }
    },
    methods: {

        eliminar(id) {
            this.$buefy.dialog.confirm({
                title: 'Eliminar colegio',
                message: '¿Estás seguro que deseas <b>eliminar</b> el colegio con id <b>' + id + '</b>?. Esta accion no se puede revertir.',
                confirmText: 'Eliminar colegio',
                type: 'is-danger',
                hasIcon: true,
                onConfirm: () => {
                    let params = {
                        id : id
                    }
                    axios.post('/api/colegios/delete', params)
                        .then(response => {
                            this.$buefy.toast.open({
                                message: response.data.message,
                                type: 'is-success'
                            })
                            this.loadAsyncData()
                        })
                        .catch(error => {
                            this.$buefy.toast.open({
                                message: 'No se pudo eliminar el colegio',
                                position: 'is-bottom',
                                type: 'is-danger'
                            })
                            throw error
                        });
                }
            })
        },

        /*
         * Load async data
         */
        loadAsyncData() {
            const params = [
                `sort_by=${this.sortField}`,
                `sort_order=${this.sortOrder}`,
                `page=${this.page}`,
                `buscar_nombre=${this.buscar_nombre}`,
                `buscar_rbd=${this.buscar_rbd}`
            ].join('&')

            this.loading = true
            axios.get(`/api/colegios/list?${params}`)
                .then(({
                    data
                }) => {
                    // api.themoviedb.org manage max 1000 pages
                    this.data = []
                    let currentTotal = data.total
                    if (data.total / this.perPage > 1000) {
                        currentTotal = this.perPage * 1000
                    }
                    this.total = currentTotal
                    data.data.forEach((item) => {
                        this.data.push(item)
                    })
                    this.loading = false
                })
                .catch((error) => {
                    this.data = []
                    this.total = 0
                    this.loading = false
                    throw error
                })
        },
        /*
         * Handle page-change event
         */
        onPageChange(page) {
            this.page = page
            this.loadAsyncData()
        },
        /*
         * Handle sort event
         */
        onSort(field, order) {
            this.sortField = field
            this.sortOrder = order
            this.loadAsyncData()
        },
        /*
         * Type style in relation to the value
         */
        type(value) {
            const number = parseFloat(value)
            if (number < 6) {
                return 'is-danger'
            } else if (number >= 6 && number < 8) {
                return 'is-warning'
            } else if (number >= 8) {
                return 'is-success'
            }
        }
    },
    filters: {
        /**
         * Filter to truncate string, accepts a length parameter
         */
        truncate(value, length) {
            return value.length > length ?
                value.substr(0, length) + '...' :
                value
        }
    },
    mounted() {
        this.loadAsyncData()
    }
}
</script>
