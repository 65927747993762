<template>
<div class="container" style="margin-top:-35px">
    <div class="box">
        <div class="columns">
            <div class="column">
                <nav class="breadcrumb" aria-label="breadcrumbs">
                    <ul>
                        <li><router-link to="/">Home</router-link></li>
                        <li class="is-active"><a href="#" aria-current="page">Usuarios</a></li>
                    </ul>
                </nav>
            </div>
        </div>
    </div>

    <div class="box">
        <div class="columns">
            <div class="column">
                <h1 class="title">Usuarios</h1>
                <p>Permite administrar a los distintos usuarios que utilizarán el sistema. Aqui debes configurar además de sus datos, los colegios que podrán visualizar en el caso de que tengan roles de sostenedor, utp, docente, ayudante o alumno.
                </p>
            </div>
        </div>

        <div class="columns">
            <div class="column is-4">
                <b-field>
                    <b-input v-model="buscar_nombre" placeholder="Buscar RUT o nombre" type="search" icon="magnify">
                    </b-input>
                </b-field>
            </div>
            <div class="column is-3">
                <b-field>
                    <b-input placeholder="Buscar email" type="search" v-model="buscar_email" icon="email">
                    </b-input>
                </b-field>
            </div>
            <div class="column is-2">
                <b-button type="is-info" icon-left="magnify" @click="loadAsyncData">Buscar</b-button>
            </div>
            <div class="column is-2">
                <b-button tag="router-link" to="/usuarios/crear" type="is-primary" icon-left="fas fa-plus" icon-pack="fas">Crear usuario</b-button>
            </div>
        </div>
        <div class="columns">
            <div class="column is-4">
                <b-field>
                    <b-input v-model="colegio" placeholder="Buscar colegio por RBD o nombre" type="search" icon="magnify">
                    </b-input>
                </b-field>
            </div>
            <div class="column is-3">
                <b-select v-model="rol" expanded placeholder="Selecciona un rol" icon="account">
                    <option value="">Todos</option>
                    <option value="4">Sostenedor</option>
                    <option value="5">UTP</option>
                    <option value="6">Docente</option>
                    <option value="7">Ayudante</option>
                    <option value="8">Alumno</option>
                </b-select>
            </div>
        </div>
        <div class="columns">
            <div class="column">
                <section>
                    <b-table :data="data" :loading="loading" paginated backend-pagination :total="total" :per-page="perPage" @page-change="onPageChange" aria-next-label="Next page" aria-previous-label="Previous page" aria-page-label="Page"
                        aria-current-label="Current page" backend-sorting :default-sort-direction="defaultSortOrder" :default-sort="[sortField, sortOrder]" @sort="onSort">

                        <b-table-column field="id" label="ID" sortable v-slot="props">
                            {{ props.row.id }}
                        </b-table-column>

                        <b-table-column field="name" label="Nombres" sortable v-slot="props">
                            {{ props.row.name | truncate(30) }}
                        </b-table-column>

                        <b-table-column field="email" label="Email" sortable v-slot="props">
                            {{ props.row.email | truncate(30) }}
                        </b-table-column>

                        <b-table-column field="genero" label="Genero" sortable v-slot="props">
                            {{ props.row.genero }}
                        </b-table-column>

                        <b-table-column field="created_at" label="Creación" sortable v-slot="props">
                            {{ props.row.created_at ? new Date(props.row.created_at).toLocaleString('ES-CL') : 'unknown' }}
                        </b-table-column>

                        <b-table-column field="accesos" label="Roles" v-slot="props">
                            <div v-show="props.row.rol" >
                                <span v-html="getRole(props.row.rol)"></span>
                                <br><br>
                            </div>
                            <ul v-for="acceso in props.row.accesos" v-bind:key="acceso.id" style="list-style-type:disc;">
                                <li class="is-size-7 mb-1"> <span v-html="getRole(acceso.rol)"></span> ({{acceso.colegio.rbd}}) {{ acceso.colegio.nombre }}</li>

                                <ul v-for="curso in props.row.cursos" v-bind:key="curso.id" style="list-style-type:circle;margin-left:20px">
                                    <li class="is-size-7 mb-1"> {{curso.nivel.nombre}} {{ curso.letra }}</li>
                                </ul>

                            </ul>
                        </b-table-column>

                        <b-table-column label="Acciones" v-slot="props">
                            <div class="buttons" v-if="props.row.rol_id !== 1">
                                <b-button type="button" class="button is-small is-info is-outlined" tag="router-link" :to="'/usuarios/' + props.row.id + '/editar'" name="button"> <i class="fas fa-edit"></i> Editar</b-button>
                                <button @click="eliminar(props.row.id)" type="button" class="button is-small is-danger is-outlined" name="button"> <i class="fas fa-trash"></i> Eliminar</button>
                            </div>

                        </b-table-column>
                        <template slot="empty">
                            <section class="section">
                                <div class="content has-text-grey has-text-centered">
                                    <p>No se encontraron usuarios</p>
                                </div>
                            </section>
                        </template>
                    </b-table>
                </section>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import axios from "axios";
export default {
    data() {
        return {
            data: [],
            total: 0,
            loading: false,
            sortField: 'id',
            sortOrder: 'desc',
            defaultSortOrder: 'desc',
            page: 1,
            perPage: 20,
            buscar_nombre: null,
            buscar_email: null,
            rol: null,
            colegio: null
        }
    },
    methods: {

        eliminar(id) {
            this.$buefy.dialog.confirm({
                title: 'Eliminar usuario',
                message: '¿Estás seguro que deseas <b>eliminar</b> el usuario con id <b>' + id + '</b>?. Esta accion no se puede revertir.',
                confirmText: 'Eliminar usuario',
                type: 'is-danger',
                hasIcon: true,
                onConfirm: () => {
                    let params = {
                        id : id
                    }
                    axios.post('/api/usuarios/delete', params)
                        .then(response => {
                            this.$buefy.toast.open({
                                message: response.data.message,
                                type: 'is-success'
                            })
                            this.loadAsyncData()
                        })
                        .catch(error => {
                            this.$buefy.toast.open({
                                message: 'No se pudo eliminar el usuario',
                                position: 'is-bottom',
                                type: 'is-danger'
                            })
                            throw error
                        });
                }
            })
        },

        getRole(role) {

            if (!role){
                return '';
            }

            let tag = '';

            switch (role.id) {
                case 1:
                    tag = 'is-dark'
                    break;
                case 2:
                    tag = 'is-primary'
                    break;
                case 3:
                    tag = 'is-info'
                    break;
                case 4:
                    tag = 'is-success'
                    break;
                case 5:
                    tag = 'is-warning'
                    break;
                case 6:
                    tag = 'is-danger'
                    break;
                case 7:
                    tag = 'is-primary'
                    break;
                case 8:
                    tag = 'is-info'
                    break;
                default:
            }
            return '<span class="tag ' + tag + '">' + role.nombre + '</span>'
        },
        /*
         * Load async data
         */
        loadAsyncData() {
            let rol = this.rol
            if (!rol) {
                rol = null
            }
            const params = [
                `sort_by=${this.sortField}`,
                `sort_order=${this.sortOrder}`,
                `page=${this.page}`,
                `buscar_nombre=${this.buscar_nombre}`,
                `buscar_email=${this.buscar_email}`,
                `buscar_rol=${rol}`,
                `buscar_colegio=${this.colegio}`
            ].join('&')

            this.loading = true
            axios.get(`/api/usuarios/list?${params}`)
                .then(({
                    data
                }) => {
                    // api.themoviedb.org manage max 1000 pages
                    this.data = []
                    let currentTotal = data.total
                    if (data.total / this.perPage > 1000) {
                        currentTotal = this.perPage * 1000
                    }
                    this.total = currentTotal
                    data.data.forEach((item) => {
                        // item.accesos = item.accesos_2021;
                        this.data.push(item)
                    })
                    this.loading = false
                })
                .catch((error) => {
                    this.data = []
                    this.total = 0
                    this.loading = false
                    throw error
                })
        },
        /*
         * Handle page-change event
         */
        onPageChange(page) {
            this.page = page
            this.loadAsyncData()
        },
        /*
         * Handle sort event
         */
        onSort(field, order) {
            this.sortField = field
            this.sortOrder = order
            this.loadAsyncData()
        },
        /*
         * Type style in relation to the value
         */
        type(value) {
            const number = parseFloat(value)
            if (number < 6) {
                return 'is-danger'
            } else if (number >= 6 && number < 8) {
                return 'is-warning'
            } else if (number >= 8) {
                return 'is-success'
            }
        }
    },
    filters: {
        /**
         * Filter to truncate string, accepts a length parameter
         */
        truncate(value, length) {
            return value.length > length ?
                value.substr(0, length) + '...' :
                value
        }
    },
    mounted() {
        this.loadAsyncData()
    }
}
</script>
