<template>
<div class="container" style="margin-top:-35px">
    <div class="box">
        <div class="columns">
            <div class="column">
                <nav class="breadcrumb" aria-label="breadcrumbs">
                    <ul>
                        <li>
                            <router-link to="/">Home</router-link>
                        </li>
                        <li class="is-active"><a href="#" aria-current="page">Preguntas</a></li>
                    </ul>
                </nav>
            </div>
        </div>
    </div>
    <div class="box">
        <div class="columns">                         
            <div class="column">
                 <b-field label="Ingresa el RUT del alumnos solo con el guion">
                    <b-input v-model="id" placeholder="0000000-9"></b-input>
                </b-field>               
                <b-button class="is-info" @click="buscar()"> buscar</b-button>              
            </div>
            <div class="column">
                 <b-field label="Ingresa el id de la asignacion (lo sacas de la url)">
                    <b-input v-model="prueba_id" placeholder="4444"></b-input>
                </b-field>               
               
            </div>
           
        </div>
    </div>
     <div class="box">
       
        <table class="table table-success  table-striped">
            <thead>
                <tr>
                    <th scope="col">ID</th>
                    <th scope="col">Prueba id</th>
                    <th scope="col">Pregunta id</th>
                    <th scope="col">Eliminar (este es el importante )</th>
                    <th scope="col">Accion</th>
                </tr>
            </thead>
            <tbody>
             
                    <tr  v-for="pregunta in preguntas" v-bind:key="pregunta.id">
                        <th scope="row">{{pregunta.id}}</th>
                        <td >{{pregunta.prueba_id}}</td>
                        <td >{{pregunta.pregunta_id}}</td>
                        <td >{{pregunta.orden}}</td>
                        <td ><b-button class="danger" @click="eliminar(pregunta.id)">Eliminar</b-button></td>
                    </tr>    
                                
               
            </tbody>
            </table>
            
    </div>
    
    
</div>
</template>
<script>
import axios from "axios";

export default {
    name: "EvaluacionesMisEvaluaciones",
    metaInfo: {
        title: 'Mis evaluaciones'
    },
    data() {
        return {
            
            id:'',
            prueba_id:'',
            preguntas: []
            
        }
    },
    methods: {
        buscar(){  
             if (!this.id) {
                this.$buefy.toast.open({
                    message: 'Debes ingresar un rut',
                    type: 'is-danger'
                })
                return
            }
             if (!this.prueba_id) {
                this.$buefy.toast.open({
                    message: 'Debes ingresar el id de la prueba',
                    type: 'is-danger'
                })
                return
            }
                    
            axios.get('/api/admin/asignacion_preguntas_buscar/'+this.id+'/'+this.prueba_id).then((response) => {
                    this.preguntas = response.data;
                }).catch((error) => {
                    throw error;
                })
                      
            },
        eliminar(id){ 
                 this.$buefy.dialog.confirm({
                title: 'Eliminar pregunta asignada',
                message: '¿Eliminaras la pregunta asigada all alumno estas segur@ ?',
                confirmText: 'si dale no mas ',
                type: 'is-danger',
                hasIcon: true,
                onConfirm: () => {
                    let params = {
                           id : id 
                    }
                    axios.post('/api/admin/eliminarRespuestaAsignada', params)
                        .then(response => {    
                             this.nolose = response.data;                       
                            this.$buefy.toast.open({
                                message: 'tamos ready se borro ',
                                type: 'is-success'
                            })
                           this.buscar();
                        })
                        .catch(error => {
                            this.$buefy.toast.open({
                                message: 'No se pudo cargar la respuestas',
                                position: 'is-bottom',
                                type: 'is-danger'
                            })
                            throw error
                        });
                }
             })
             
        }
        
      
      
    },
    mounted() {
        
    }
}
</script>
