<template>
    <div class="container" style="margin-top:-35px"> 
        <div class="box">
                <div class="columns">
                    <div class="column">
                        <nav class="breadcrumb" aria-label="breadcrumbs">
                            <ul>
                                <li>
                                    <router-link to="/">Home</router-link>
                                </li>
                                <li class="is-active"><a href="#" aria-current="page">Preguntas Modificar</a></li>
                            </ul>
                        </nav>
                    </div>
                </div>
        </div>
    <div class="box">
        <div class="columns">                         
            <div class="column">
                 <b-field label="ID de la pregunta a modificar">
                    <b-input v-model="id" placeholder="Ingrese el id"></b-input>
                </b-field>
                <b-button class="is-info" @click="buscar()"> Buscar</b-button>
            </div>
            <div class="column">
            </div>
            <div class="column">
            </div>
        </div>
    </div>
    <div class="box">
        <div class="columns">                         
            <div class="column">
                <b-field label="prueba id (aqui cambiar el nuedo id de la prueba )">
                    <b-input disabled="true" v-model="pregunta.id" placeholder="prueba_id" readonly></b-input>
                </b-field>
                 <b-field label="grupo (cambiar dependiendo de la ultima pregunta de la nueva prueba)" >
                    <b-input v-model="pregunta.grupo"  placeholder="prueba_id" ></b-input>
                </b-field>
                <b-field label="orden (aqui copiar lo mism del orden )">
                    <b-input v-model="pregunta.orden" placeholder="prueba_id"></b-input>
                </b-field>
                <b-field label="habilidad_id">
                    <b-input v-model="pregunta.habilidad_id" placeholder="prueba_id"></b-input>
                </b-field>
                <b-field label="eje_id">
                    <b-input v-model="pregunta.eje_id" placeholder="prueba_id"></b-input>
                </b-field>
                <b-field label="objetivo_id">
                    <b-input v-model="pregunta.objetivo_id" placeholder="prueba_id"></b-input>
                </b-field>
                <b-field label="indicador_id">
                    <b-input v-model="pregunta.indicador_id" placeholder="prueba_id"></b-input>
                </b-field>
                <b-button class="is-info" @click="modificar()"> Modificar</b-button>
                
               
            </div>
            <div class="column">
                <b-field label="enunciado">
                    <b-input v-model="pregunta.enunciado" placeholder="enunciado"></b-input>
                </b-field>
                 <b-field label="texto">
                    <b-input v-model="pregunta.texto" placeholder="texto"></b-input>
                </b-field>
                 <b-field label="pregunta">
                    <b-input v-model="pregunta.pregunta" placeholder="pregunta"></b-input>
                </b-field>
                 <b-field label="clave">
                    <b-input v-model="pregunta.clave" placeholder="clave"></b-input>
                </b-field>
                 <b-field label="dificultad_id">
                    <b-input v-model="pregunta.dificultad_id" placeholder="dificultad_id"></b-input>
                </b-field>
                 <b-field label="puntaje">
                    <b-input v-model="pregunta.puntaje" placeholder="puntaje"></b-input>
                </b-field>
                <b-field label="pregunta_imagen">
                    <b-input v-model="pregunta.pregunta_imagen" placeholder="pregunta_imagen"></b-input>
                </b-field>
                 <b-field label="pregunta_md5">
                    <b-input v-model="pregunta.pregunta_md5" placeholder="pregunta_md5"></b-input>
                </b-field>                
            </div>
        </div>
        <div class="column">
                <b-button class="is-danger" @click="limpiar()"> limpiar todo el formulario</b-button>
            </div>
    </div>
    </div>
</template>

<script>
import axios from "axios";
export default {
    name: "EvaluacionesMisEvaluaciones",
    metaInfo: {
        title: 'Mis evaluaciones'
    },
    data() {
        return {
            contador : 0,
            id: 1,
            prueba_id:null,
            id_nueva_pregunta:null,
            nolose:[],
            preguntas:[],
            
            pregunta:[{
                id:null,
                prueba_id : null ,
                grupo:null,
                orden:null,
                habilidad_id:null,
                eje_id:null,
                objetivo_id:null,
                indicador_id:null,
                puntaje:null,
                enunciado:null,
                texto:null,
                pregunta:null,
                clave:null,
                pregunta_imagen:null,
                dificultad_id:null,               
                pregunta_md5:null,
            }
            ]
        }
    },
    methods: {
        buscar(){          
            axios.get('/api/admin/pregunta_buscar/'+this.id).then((response) => {
                    this.pregunta = response.data;
                }).catch((error) => {
                    throw error;
                })
            },
        limpiar(){          
                this.contador  =  0,
               this.id =  1,
               this.prueba_id = null,
               this.id_nueva_pregunta = null,
               this.nolose = [],
               this.preguntas = [],
               
                this.pregunta=[{
                    
                    prueba_id : null ,
                    grupo:null,
                    orden:null,
                    habilidad_id:null,
                    eje_id:null,
                    objetivo_id:null,
                    indicador_id:null,
                    puntaje:null,
                    enunciado:null,
                    texto:null,
                    pregunta:null,
                    clave:null,
                    dificultad_id:null,               
                    pregunta_md5:null,
                }
                ]          
            },
        
        modificar(){
             this.$buefy.dialog.confirm({
                title: 'modificar pregunta',
                message: 'Haz cambiado algo de la pregunta, ¿estas seguro de que quieres guardar lso cambios?',
                confirmText: 'yes',
                type: 'is-danger',
                hasIcon: true,
                onConfirm: () => {
                    let params = {
                            id: this.id,
                            prueba_id  :  this.prueba_id ,
                            grupo : this.pregunta.grupo,
                            orden : this.pregunta.orden,
                            habilidad_id : this.pregunta.habilidad_id,
                            eje_id : this.pregunta.eje_id,
                            objetivo_id : this.pregunta.objetivo_id,
                            indicador_id : this.pregunta.indicador_id,
                            puntaje : this.pregunta.puntaje,
                            enunciado : this.pregunta.enunciado,
                            texto : this.pregunta.texto,
                            pregunta : this.pregunta.pregunta,
                            pregunta_imagen : this.pregunta.pregunta_imagen,
                            clave : this.pregunta.clave,
                            dificultad_id : this.pregunta.dificultad_id,               
                            pregunta_md5 : this.pregunta.pregunta_md5,
                    }
                    axios.post('/api/admin/modificar', params)
                        .then(response => {
                            this.id_nueva_pregunta = response.data;
                            this.$buefy.toast.open({
                                message: response.data.message,
                                type: 'is-success'
                            })
                           
                        })
                        .catch(error => {
                            this.$buefy.toast.open({
                                message: 'No se pudo cargar la pregunta',
                                position: 'is-bottom',
                                type: 'is-danger'
                            })
                            throw error
                        });
                }
             })}
      
    },
    mounted() {
        
    }
}
</script>
