<template>
<div class="container" style="margin-top:-35px">
    <div class="box">
        <div class="columns">
            <div class="column">
                <nav class="breadcrumb" aria-label="breadcrumbs">
                    <ul>
                        <li>
                            <router-link to="/">Home</router-link>
                        </li>
                        <li class="is-active"><a href="#" aria-current="page">Preguntas</a></li>
                    </ul>
                </nav>
            </div>
        </div>
    </div>
    <div class="box">
        <div class="columns">                         
            <div class="column">
                 <b-field label="ID de la pregunta a copiar">
                    <b-input v-model="id" placeholder="Ingrese el id"></b-input>
                </b-field>
                <b-button class="is-info" @click="buscar()"> buscar</b-button>
                <h1>ULTIMA PREGUNTA CREADA = {{id}}</h1>
            </div>
            <div class="column">

            </div>
            <div class="column">
                <b-field label="ID de la prueba a copiar la pregunta">
                    <b-input v-model="prueba_id" placeholder="Ingrese el id"></b-input>
                </b-field>

                <h1>Ingresa ester orden antes de isertar la pregunta : {{preguntas.length+1}}</h1>
                <b-button class="is-info" @click="ultima_orden(prueba_id)"> Orden</b-button>
                 
            </div>
        </div>
    </div>
     <div class="box">
        <div class="columns">                         
            <div class="column">
                <b-field label="prueba id (aqui cambiar el nuedo id de la prueba )">
                    <b-input v-model="prueba_id" placeholder="prueba_id"></b-input>
                </b-field>
                 <b-field label="grupo (cambiar dependiendo de la ultima pregunta de la nueva prueba)" >
                    <b-input v-model="pregunta.grupo"  placeholder="prueba_id"></b-input>
                </b-field>
                <b-field label="orden (aqui copiar lo mism del orden )">
                    <b-input v-model="pregunta.orden" placeholder="prueba_id"></b-input>
                </b-field>
                <b-field label="habilidad_id">
                    <b-input v-model="pregunta.habilidad_id" placeholder="prueba_id"></b-input>
                </b-field>
                <b-field label="eje_id">
                    <b-input v-model="pregunta.eje_id" placeholder="prueba_id"></b-input>
                </b-field>
                <b-field label="objetivo_id">
                    <b-input v-model="pregunta.objetivo_id" placeholder="prueba_id"></b-input>
                </b-field>
                <b-field label="indicador_id">
                    <b-input v-model="pregunta.indicador_id" placeholder="prueba_id"></b-input>
                </b-field>
                <b-button class="is-info" @click="guardar()"> insertar pregunta</b-button>
                
               
            </div>
            <div class="column">
                <b-field label="enunciado">
                    <b-input v-model="pregunta.enunciado" placeholder="enunciado"></b-input>
                </b-field>
                 <b-field label="texto">
                    <b-input v-model="pregunta.texto" placeholder="texto"></b-input>
                </b-field>
                 <b-field label="pregunta">
                    <b-input v-model="pregunta.pregunta" placeholder="pregunta"></b-input>
                </b-field>
                 <b-field label="clave">
                    <b-input v-model="pregunta.clave" placeholder="clave"></b-input>
                </b-field>
                 <b-field label="dificultad_id">
                    <b-input v-model="pregunta.dificultad_id" placeholder="dificultad_id"></b-input>
                </b-field>
                 <b-field label="puntaje">
                    <b-input v-model="pregunta.puntaje" placeholder="puntaje"></b-input>
                </b-field>
                 <b-field label="pregunta_md5">
                    <b-input v-model="pregunta.pregunta_md5" placeholder="pregunta_md5"></b-input>
                </b-field>                
            </div>
        </div>
    </div>
    <div class="box">
            <h1>ESTE ES EL NUEVO ID DE LA PREGUNTA CREADA SI ONO : {{id_nueva_pregunta}}</h1>
        <div v-for="respuesta in respuestas" v-bind:key="respuesta.id">
                <div class="columns">
                    <div class="column">
                         <b-field label="prueba_id">
                            <b-input v-model="prueba_id" :value="prueba_id" laceholder="prueba_id"></b-input>
                        </b-field>
                    </div>
                     <div class="column">
                         <b-field label="pregunta_id">
                            <b-input v-model="respuesta.pregunta_id"  placeholder="pregunta_id"></b-input>
                        </b-field>
                    </div>
                    <div class="column">
                         <b-field label="respuesta">
                            <b-input v-model="respuesta.respuesta" placeholder="respuesta"></b-input>
                        </b-field>
                    </div>
                    <div class="column">
                         <b-field label="letra">
                            <b-input v-model="respuesta.letra" placeholder="letra"></b-input>
                        </b-field>
                    </div>
                </div>
        </div>
        <div class="columns">
            <div class="column">
                <b-button class="is-info" @click="guardarrespuestas()"> insertar respuestas</b-button>
            </div>
            <div class="column">
                <b-button class="is-danger" @click="limpiar()"> limpiar todo el formulario</b-button>

            </div>
        </div>
    </div>
    
</div>
</template>
<script>
import axios from "axios";

export default {
    name: "EvaluacionesMisEvaluaciones",
    metaInfo: {
        title: 'Mis evaluaciones'
    },
    data() {
        return {
            contador : 0,
            id: 1,
            prueba_id:null,
            id_nueva_pregunta:null,
            nolose:[],
            preguntas:[],
            respuestas:[],
            respuesta:[{
                prueba_id:null,
                pregunta_id:null,
                respuesta:null,
                letra:null,
            }],
            pregunta:[{
                prueba_id : null ,
                grupo:null,
                orden:null,
                habilidad_id:null,
                eje_id:null,
                objetivo_id:null,
                indicador_id:null,
                puntaje:null,
                enunciado:null,
                texto:null,
                pregunta:null,
                clave:null,
                dificultad_id:null,               
                pregunta_md5:null,
            }
            ]
        }
    },
    methods: {
        buscar(){          
            axios.get('/api/admin/pregunta_buscar/'+this.id).then((response) => {
                    this.pregunta = response.data;
                }).catch((error) => {
                    throw error;
                })
                axios.get('/api/admin/respuestas/'+this.id).then((response) => {
                    this.respuestas = response.data;
                }).catch((error) => {
                    throw error;
                })            
            },
        limpiar(){          
                this.contador  =  0,
              //this.id =  1,
               this.prueba_id = null,
               this.id_nueva_pregunta = null,
               this.nolose = [],
               this.preguntas = [],
               this.respuestas = [],
               this.respuesta = [{
                    prueba_id : null,
                    pregunta_id : null,
                    respuesta : null,
                    letra : null,
                }],
                this.pregunta=[{
                    prueba_id : null ,
                    grupo:null,
                    orden:null,
                    habilidad_id:null,
                    eje_id:null,
                    objetivo_id:null,
                    indicador_id:null,
                    puntaje:null,
                    enunciado:null,
                    texto:null,
                    pregunta:null,
                    clave:null,
                    dificultad_id:null,               
                    pregunta_md5:null,
                }
                ]          
            },
        ultima_orden(id){
             axios.get('/api/admin/list_preguntas/'+id).then((response) => {
                    this.preguntas = response.data;
                }).catch((error) => {
                    throw error;
                })
        },
        guardarrespuestas(){
             this.$buefy.dialog.confirm({
                title: 'guardar pregunta',
                message: '¿cambiastes el id de la prueba al nuevo id?. ¿cambiastes el id de la pregunta ?',
                confirmText: 'yes',
                type: 'is-danger',
                hasIcon: true,
                onConfirm: () => {
                    let params = {
                            respuesta  :  this.respuestas ,
                            prueba_id : this.prueba_id
                         
                    }
                    axios.post('/api/admin/insertRespuesta', params)
                        .then(response => {    
                             this.nolose = response.data;                       
                            this.$buefy.toast.open({
                                message: 'tamos ready',
                                type: 'is-success'
                            })
                           
                        })
                        .catch(error => {
                            this.$buefy.toast.open({
                                message: 'No se pudo cargar la respuestas',
                                position: 'is-bottom',
                                type: 'is-danger'
                            })
                            throw error
                        });
                }
             })
        },
        guardar(){
             this.$buefy.dialog.confirm({
                title: 'guardar pregunta',
                message: '¿cambiastes el id de la prueba al nuevo id?. ¿cambiastes el orden y grupo ?',
                confirmText: 'yes',
                type: 'is-danger',
                hasIcon: true,
                onConfirm: () => {
                    let params = {
                            prueba_id  :  this.prueba_id ,
                            grupo : this.pregunta.grupo,
                            orden : this.pregunta.orden,
                            habilidad_id : this.pregunta.habilidad_id,
                            eje_id : this.pregunta.eje_id,
                            objetivo_id : this.pregunta.objetivo_id,
                            indicador_id : this.pregunta.indicador_id,
                            puntaje : this.pregunta.puntaje,
                            enunciado : this.pregunta.enunciado,
                            texto : this.pregunta.texto,
                            pregunta : this.pregunta.pregunta,
                            clave : this.pregunta.clave,
                            dificultad_id : this.pregunta.dificultad_id,               
                            pregunta_md5 : this.pregunta.pregunta_md5,
                    }
                    axios.post('/api/admin/insert', params)
                        .then(response => {
                            this.id_nueva_pregunta = response.data;
                            this.$buefy.toast.open({
                                message: response.data.message,
                                type: 'is-success'
                            })
                           
                        })
                        .catch(error => {
                            this.$buefy.toast.open({
                                message: 'No se pudo cargar la pregunta',
                                position: 'is-bottom',
                                type: 'is-danger'
                            })
                            throw error
                        });
                }
             })}
      
    },
    mounted() {
        
    }
}
</script>
