<template>
  <div class="container">
    <div class="box">
      <h1 class="title is-3 has-text-info">
        Cargar Objetivos 2023
      </h1>
      <div class="columns">
        <div class="column">
          <div class="box has-background-light">
            <div class="columns">
              <div class="column is-6">
                <h1 class="title is-5 has-text-info">Asignaturas</h1>
              </div>
              <div class="column is-6">
                <b-field label="Seleccione">
                  <b-select
                    v-model="asignatura"
                    expanded
                    placeholder="Selecciona una asignatura"
                  >
                    <option
                      v-for="asignatura in asignaturas"
                      :value="asignatura.id"
                      :key="asignatura.id"
                    >
                      {{ asignatura.nombre }}
                    </option>
                  </b-select>
                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column is-5">
                <h1 class="title is-5 has-text-info">Archivo</h1>
              </div>
              <div class="column is-4">
                <section>
                  <b-upload v-model="file" class="file-label">
                    <span class="file-cta">
                      <span class="file-label">Seleccione</span>
                    </span>
                    <span class="file-name" v-if="file">
                      {{ file.name }}
                    </span>
                  </b-upload>
                </section>
              </div>
              <div class="column is-3">
                <b-button
                  type="is-primary"
                  class="ml-2"
                  icon-left="fas fa-upload"
                  icon-pack="fas"
                  @click="carga"
                  >Cargar</b-button
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";

export default {
  name: "Objetivos2023",
  metaInfo: {
    title: "Cargar Objetivos 2023"
  },
  data() {
    return {
      datos: [],
      ides: [],
      asignaturas: [],
      niveles: [],
      nivel: null,
      asignatura: null,
      file: null,
      fix_prueba_nueva: null,
      fix_pregunta_sin_respuesta: null,
      mensaje: null
    };
  },
  methods: {
    getAsignaturas() {
      axios
        .get("/api/asignaturas/select")
        .then(res => {
          this.asignaturas = res.data;
        })
        .catch(err => {
          throw err;
        });
    },
    carga() {
      let formData = new FormData();
      this.estado = null;
      formData.append("file", this.file);
      formData.append("asignatura", this.asignatura);

      axios.post("api/objetivos/cargar/2023", formData).then(res => {
        console.log(res.data);
      });
    }
  },
  mounted() {
    this.getAsignaturas();
  }
};
</script>
