import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

import auth from "../middleware/auth";

import Usuarios from "../views/usuarios/List.vue";
import Preguntas from "../views/preguntas/List.vue";
import PreguntasRepetidas from "../views/preguntas/repetidas.vue";
import Revisar from "../views/revisar/alumnos.vue";
import Modificar from "../views/preguntas/Modificar.vue";
import Pruebas from "../views/pruebas/List.vue";
import UsuariosCrear from "../views/usuarios/Form.vue";
import UsuariosEditar from "../views/usuarios/Form.vue";
import ParcialArray from "../views/preguntas/parcialarray.vue";

import Colegios from "../views/colegios/List.vue";
import ColegiosCrear from "../views/colegios/Form.vue";
import ColegiosEditar from "../views/colegios/Form.vue";

import Cursos from "../views/cursos/List.vue";
import CursosCrear from "../views/cursos/Form.vue";
import CursosAlumnos from "../views/cursos/alumnos/List.vue";

import FormatoAeduc from "../views/formato_aeduc/FormatoAeduc.vue";
import Carga from "../views/Carga.vue";

import Objetivos2023 from "../views/cargar/objetivos2023.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      middleware: auth
    }
  },
  {
    path: "/usuarios",
    name: "Usuarios",
    component: Usuarios,
    meta: {
      middleware: auth
    }
  },
  {
    path: "/preguntas",
    name: "Preguntas",
    component: Preguntas,
    meta: {
      middleware: auth
    }
  },
  {
    path: "/preguntas/repetidas",
    name: "Preguntas",
    component: PreguntasRepetidas,
    meta: {
      middleware: auth
    }
  },
  {
    path: "/preguntas/modificar",
    name: "Preguntas",
    component: Modificar,
    meta: {
      middleware: auth
    }
  },
  {
    path: "/pruebas",
    name: "Pruebas",
    component: Pruebas,
    meta: {
      middleware: auth
    }
  },
  {
    path: "/revisar/alumnos",
    name: "Alumnos",
    component: Revisar,
    meta: {
      middleware: auth
    }
  },
  {
    path: "/usuarios/crear",
    name: "UsuariosCrear",
    component: UsuariosCrear,
    meta: {
      middleware: auth
    }
  },
  {
    path: "/usuarios/:id/editar",
    name: "UsuariosEditar",
    component: UsuariosEditar,
    meta: {
      middleware: auth
    }
  },
  {
    path: "/colegios",
    name: "Colegios",
    component: Colegios,
    meta: {
      middleware: auth
    }
  },
  {
    path: "/colegios/crear",
    name: "ColegiosCrear",
    component: ColegiosCrear,
    meta: {
      middleware: auth
    }
  },
  {
    path: "/colegios/:id/editar",
    name: "ColegiosEditar",
    component: ColegiosEditar,
    meta: {
      middleware: auth
    }
  },
  {
    path: "/cursos/:id/",
    name: "Cursos",
    component: Cursos,
    meta: {
      middleware: auth
    }
  },
  {
    path: "/cursos/:id/crear/",
    name: "CursosCrear",
    component: CursosCrear,
    meta: {
      middleware: auth
    }
  },
  {
    path: "/cursos/:id/alumnos/:curso/",
    name: "CursosAlumnos",
    component: CursosAlumnos,
    meta: {
      middleware: auth
    }
  },
  {
    path: "/formatoaeduc",
    name: "FormatoAeduc",
    component: FormatoAeduc,
    meta: {
      middleware: auth
    }
  },
  {
    path: "/preguntas/parcialarray",
    name: "ParcialArray",
    component: ParcialArray,
    meta: {
      middleware: auth
    }
  },
  {
    path: "/cargar/objetivos2023",
    name: "Objetivos2023",
    component: Objetivos2023,
    meta: {
      middleware: auth
    }
  },
  {
    path: "/carga",
    name: "carga",
    component: Carga,
    meta: {
      middleware: auth
    }
  }

  /*,
        {
            path: '/404',
            name: 'Notfound'
        },
        {
            path: '*',
            redirect: '/404'
        }*/
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

// Creates a `nextMiddleware()` function which not only
// runs the default `next()` callback but also triggers
// the subsequent Middleware function.
function nextFactory(context, middleware, index) {
  const subsequentMiddleware = middleware[index];
  // If no subsequent Middleware exists,
  // the default `next()` callback is returned.
  if (!subsequentMiddleware) return context.next;

  return (...parameters) => {
    // Run the default Vue Router `next()` callback first.
    context.next(...parameters);
    // Then run the subsequent Middleware with a new
    // `nextMiddleware()` callback.
    const nextMiddleware = nextFactory(context, middleware, index, 1);
    subsequentMiddleware({ ...context, next: nextMiddleware });
  };
}

router.beforeEach((to, from, next) => {
  if (to.meta.middleware) {
    const middleware = Array.isArray(to.meta.middleware)
      ? to.meta.middleware
      : [to.meta.middleware];

    const context = {
      from,
      next,
      router,
      to
    };
    const nextMiddleware = nextFactory(context, middleware, 1);

    return middleware[0]({ ...context, next: nextMiddleware });
  }

  return next();
});

export default router;
