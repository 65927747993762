<template>
<div class="container" style="margin-top:-35px">
    <div class="box">
        <div class="columns">
            <div class="column">
                <nav class="breadcrumb" aria-label="breadcrumbs">
                    <ul>
                        <li>
                            <router-link to="/">Home</router-link>
                        </li>
                        <li>
                            <router-link :to="'/cursos/' + id">Cursos</router-link>
                        </li>
                        <li class="is-active"><a href="#" aria-current="page">Crear</a></li>
                    </ul>
                </nav>
            </div>
        </div>
    </div>
    <div class="box">
        <div class="columns">
            <div class="column">
                <h1 class="title">Cursos</h1>
                <p>Completa el formulario para crear o editar un curso. En la sección de más abajo puedes crear un curso seleccionando el rango de letras.</p>
            </div>
        </div>
        <div class="columns">
            <div class="column">
                <b-field label="Nivel">
                    <b-select v-model="nivel" ref="nivel" expanded placeholder="Selecciona un nivel">
                        <option v-for="nivel in niveles" :value="nivel.id" :key="nivel.id">
                            {{ nivel.nombre }}
                        </option>
                    </b-select>
                </b-field>
            </div>
            <div class="column">
                <b-field label="Letra" grouped>
                    <b-input v-model="letra" ref="letra" expanded placeholder="Ingresa la letra del curso"></b-input>
                    <p class="control">
                        <b-button type="is-primary" :loading="crearCursoLoading" @click="crearCurso">Crear curso</b-button>
                    </p>
                </b-field>
            </div>
        </div>
    </div>
    <div class="box">
        <div class="columns">
            <div class="column">
                <h2 class="subtitle">Crear por rango</h2>
                <p>Selecciona los rangos de cursos que deseas crear, al ingresar la letra, el sistema generará todas las letras del abcedario hasta llegar a la letra limite ingresada.</p>
            </div>
        </div>
        <div class="columns">
            <div class="column">
                <b-field label="Nivel inicial">
                    <b-select v-model="nivel_inicio" expanded placeholder="Selecciona un nivel inicial">
                        <option v-for="nivel in niveles" ref="nivel_inicio" :value="nivel.id" :key="nivel.id">
                            {{ nivel.nombre }}
                        </option>
                    </b-select>
                </b-field>
            </div>
            <div class="column">
                <b-field label="Nivel final">
                    <b-select v-model="nivel_fin" expanded placeholder="Selecciona un nivel final">
                        <option v-for="nivel in niveles" ref="nivel_fin" :value="nivel.id" :key="nivel.id">
                            {{ nivel.nombre }}
                        </option>
                    </b-select>
                </b-field>
            </div>
            <div class="column">
                <b-field label="Letra limite" grouped>
                    <b-input v-model="letra_fin" expanded ref="letra_fin" placeholder="Letra limite del curso a crear"></b-input>
                    <p class="control">
                        <b-button type="is-primary" :loading="crearCursosLoading" @click="crearCursos">Crear cursos</b-button>
                    </p>
                </b-field>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import axios from 'axios';

export default {
    data() {
        return {
            id: this.$route.params.id,
            niveles: [],
            letra: null,
            nivel: null,
            nivel_inicio: null,
            nivel_fin: null,
            letra_fin: null,
            crearCursoLoading: false,
            crearCursosLoading: false
        }
    },
    methods: {
        getNiveles() {
            axios.get('/api/niveles/get').then((res) => {
                this.niveles = res.data;
            }).catch((err) => {
                throw err
            })
        },
        crearCurso() {

            let self = this

            if (!this.nivel) {
                this.$buefy.toast.open({
                    message: `Debes seleccionar un nivel`,
                    position: 'is-bottom',
                    type: 'is-danger'
                })
                this.$refs.nivel.focus();
                return
            }

            if (!this.letra) {
                this.$buefy.toast.open({
                    message: `Debes seleccionar una letra`,
                    position: 'is-bottom',
                    type: 'is-danger'
                })
                this.$refs.letra.focus();
                return
            }

            self.crearCursoLoading = true;

            var params = {
                params: this.$data
            };

            axios.post('/api/cursos/store', params).then((response) => {
                self.crearCursoLoading = false;
                if (response.data.status) {
                    this.$buefy.toast.open({
                        message: response.data.message,
                        type: 'is-success'
                    })
                } else {
                    this.$buefy.toast.open({
                        message: response.data.message,
                        position: 'is-bottom',
                        type: 'is-danger'
                    })
                }
            }).catch((err) => {
                self.crearCursoLoading = false;
                throw err
            })
        },
        crearCursos() {
            let self = this

            if (!this.nivel_inicio) {
                this.$buefy.toast.open({
                    message: `Debes seleccionar un nivel inicial`,
                    position: 'is-bottom',
                    type: 'is-danger'
                })
                this.$refs.nivel_inicio.focus();
                return
            }

            if (!this.nivel_fin) {
                this.$buefy.toast.open({
                    message: `Debes seleccionar un nivel final`,
                    position: 'is-bottom',
                    type: 'is-danger'
                })
                this.$refs.nivel_fin.focus();
                return
            }

            if (!this.letra_fin) {
                this.$buefy.toast.open({
                    message: `Debes seleccionar una letra límite`,
                    position: 'is-bottom',
                    type: 'is-danger'
                })
                this.$refs.letra_fin.focus();
                return
            }

            self.crearCursosLoading = true;

            var params = {
                params: this.$data
            };

            axios.post('/api/cursos/store/masivo', params).then((response) => {
                self.crearCursosLoading = false;
                if (response.data.status) {
                    this.$buefy.toast.open({
                        message: response.data.message,
                        type: 'is-success'
                    })
                } else {
                    this.$buefy.toast.open({
                        message: response.data.message,
                        position: 'is-bottom',
                        type: 'is-danger'
                    })
                }
            }).catch((err) => {
                self.crearCursosLoading = false;
                throw err
            })
        }
    },
    mounted() {
        this.getNiveles()
    }
}
</script>
