<template>
  <div class="container">
    <div class="box">
      <h1 class="title is-3 has-text-info">
        Hola, {{ user.name }} bienvenido al administrador de AEDUC
      </h1>
      <div class="columns">
        <div class="column is-4">
          <div class="box has-background-light">
            <div class="columns">
              <div class="column is-6 pr-0 pl-5 ">
                <h1 class="title is-3 mt-2 has-text-info ">
                  {{ colegios.length }}
                </h1>
              </div>
              <div class="column is-6">
                <h1 class="subtitle is-5">Colegios</h1>
                <h1 class="title is-5">totales</h1>
              </div>
            </div>
          </div>
          <div class="box has-background-light">
            <div class="columns">
              <div class="column is-6 pr-0 pl-5 ">
                <h1 class="title is-3 mt-2 has-text-info ">
                  {{ usuarios.length }}
                </h1>
              </div>
              <div class="column is-6">
                <h1 class="subtitle is-5">Usuarios</h1>
                <h1 class="title is-5">totales</h1>
              </div>
            </div>
            <hr class="has-background-info" />
            <div class="columns">
              <div class="column is-3">
                <h1 class="has-text-centered">Docentes</h1>
                <h2 class="has-text-info has-text-centered">
                  {{ docentes.length }}
                </h2>
              </div>
              <div class="column is-3">
                <h1 class="has-text-centered">Alumnos</h1>
                <h2 class="has-text-info has-text-centered">
                  {{ alumnos.length }}
                </h2>
              </div>
              <div class="column is-3">
                <h1 class="has-text-centered">UTP</h1>
                <h2 class="has-text-info has-text-centered">
                  {{ utp.length }}
                </h2>
              </div>
              <div class="column is-3">
                <h1 class="has-text-centered">Otros</h1>
                <h2 class="has-text-info has-text-centered">
                  {{
                    usuarios.length -
                      (docentes.length + alumnos.length + utp.length)
                  }}
                </h2>
              </div>
            </div>
          </div>
        </div>
        <div class="column is-8">
          <div class="box">
            <div class="columns">
              <div class="column is-2 has-text-centered pt-5">
                <b-icon pack="fas" icon="users" type="is-info" size="is-large">
                </b-icon>
              </div>
              <div class="column is-8">
                <h1 class="title is-5">Usuarios</h1>
                <h1 class="subtitle is-5">
                  Permite administrar a los distintos usuarios que utilizarán el
                  sistema. Aqui debes configurar además de sus datos, los
                  colegios que podrán visualizar en el caso de que tengan roles
                  de sostenedor, utp, docente, ayudante o alumno.
                </h1>
              </div>
              <div class="column is-2 pl-6 pr-0 pt-5 pb-0 ">
                <b-button
                  tag="router-link"
                  :to="'/usuarios'"
                  type="is-info"
                  class="is-fullheight mt-3"
                  ><i class="fas fa-chevron-right"></i
                ></b-button>
              </div>
            </div>
          </div>
          <div class="box">
            <div class="columns">
              <div class="column is-2 has-text-centered pt-5">
                <b-icon pack="fas" icon="school" type="is-info" size="is-large">
                </b-icon>
              </div>
              <div class="column is-8">
                <h1 class="title is-5">Colegios</h1>
                <h1 class="subtitle is-5">
                  Permite administrar colegios creados para utilizar el sistema.
                </h1>
              </div>
              <div class="column is-2 pl-6 pr-0 pt-2 pb-0 ">
                <b-button
                  tag="router-link"
                  :to="'/colegios'"
                  type="is-info"
                  class="is-fullheight mt-3"
                  ><i class="fas fa-chevron-right"></i
                ></b-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";

export default {
  name: "Home",
  computed: {
    ...mapGetters({
      authenticated: "auth/authenticated",
      user: "auth/user"
    })
  },
  data() {
    return {
      colegios: [],
      usuarios: [],
      alumnos: [],
      docentes: [],
      utp: []
    };
  },
  methods: {
    allcolegios() {
      axios
        .get("/api/colegio/allColegios", {})
        .then(res => {
          this.colegios = res.data;
        })
        .catch(err => {
          throw err;
        });
    },
    allusuarios() {
      axios
        .get("/api/usuarios/allUsuarios", {})
        .then(res => {
          this.usuarios = res.data;
        })
        .catch(err => {
          throw err;
        });
    },
    alldocentes() {
      axios
        .get("/api/usuarios/allDocentes", {})
        .then(res => {
          this.docentes = res.data;
        })
        .catch(err => {
          throw err;
        });
    },
    allalumnos() {
      axios
        .get("/api/usuarios/allAlumnos", {})
        .then(res => {
          this.alumnos = res.data;
        })
        .catch(err => {
          throw err;
        });
    },
    allutp() {
      axios
        .get("/api/usuarios/allUtp", {})
        .then(res => {
          this.utp = res.data;
        })
        .catch(err => {
          throw err;
        });
    }
  },
  mounted() {
    this.allcolegios();
    this.allusuarios();
    this.alldocentes();
    this.allalumnos();
    this.allutp();
  }
};
</script>
