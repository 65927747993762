<template>
<div class="container" style="margin-top:-35px">
    <div class="box">
        <div class="columns">
            <div class="column">
                <nav class="breadcrumb" aria-label="breadcrumbs">
                    <ul>
                        <li>
                            <router-link to="/">Home</router-link>
                        </li>
                        <li class="is-active"><a href="#" aria-current="page">Pruebas</a></li>
                    </ul>
                </nav>
            </div>
        </div>
    </div>
    <div class="box">
        <div class="columns">
            <div class="column">
                <h1 class="title">Pruebas</h1>
               
                <p>Aqui podras ver todas las pruebas creadas por los docentes, podras eliminar y reabrir pruebas cerradas para seguir editando.</p>
            </div>
        </div>
    </div>
    <div class="box">       
        <section>             
            <b-tabs>
                <b-tab-item label="Buscar por ID para Habilitar o desabilitar">
                    <div class="columns">                         
                        <div class="column">
                            <b-field label="Ingresa el ID de la prueba ">
                                <b-input v-model="id" placeholder="aqui ID"></b-input>
                            </b-field>               
                            <b-button class="is-info" @click="buscar()"> buscar</b-button>              
                        </div>
                        <div class="column">
                          
                        </div>
                    </div>
                    <div class="columns">
                        <div class="column" v-show="prueba.id">
                              <table class="table  table-striped is-fullwidth" >
                                <thead>
                                        <tr >
                                            <th> ID</th>
                                            <th> Origen</th>
                                            <th> Etapa</th>
                                            <th> Tipo</th>
                                            <th> Asignatura</th>
                                            <th> Nivel</th>
                                            <th> Estado</th>
                                        </tr> 
                                </thead>
                                <tbody>
                                        <tr>
                                            <th> {{prueba.id}}</th>
                                            <th v-if="prueba.origen == 3"> <span class="tag is-danger"> AEDUC desabilitada </span></th>
                                            <th v-else-if="!prueba.origen"><span class="tag is-success">AEDUC habilitada  </span>  </th>
                                            <th v-else-if ="prueba.origen"><span class="tag is-info">  Profesor </span></th>
                                            <th> {{prueba.prueba_etapa.nombre}}</th>
                                            <th> {{prueba.prueba_tipo.nombre}}</th>
                                            <th> {{prueba.asignatura.nombre}}</th>
                                            <th> {{prueba.nivel.nombre}}</th>
                                            <th v-if="prueba.estado == 1"> <span class="tag is-danger">No editable </span></th>
                                            <th v-else-if="prueba.estado == 0"> <span class="tag is-success">Editable </span></th>
                                        </tr> 
                                </tbody>                                              
                            </table>
                        </div>
                    </div>
                    <div class="columns">
                           <div class="column is-centered" v-show="prueba.id">
                                <div v-if="!prueba.origen">
                                    <b-button class="is-danger" size="lg" @click="desabilitar()"> Desabilitar</b-button>            
                                </div>
                                <div v-else-if="prueba.origen == 3" >
                                    <b-button class="is-success" size="lg" @click="habilitar()"> Habilitar</b-button>              
                                </div>
                                <div v-else-if="prueba.origen" >
                                    <br>
                                    <h1 class="title has-background-danger">Esta prueba es de un docente no nos pertence </h1>
                                </div>
                           </div>
                    </div>
                           
                </b-tab-item>           

                <b-tab-item  label="Todas las pruebas">
                    
                    <div class="columns">
                        <div class="column">
                            <b-table :data="data" :loading="loading" paginated backend-pagination :total="total" :per-page="perPage" @page-change="onPageChange" aria-next-label="Next page" aria-previous-label="Previous page" aria-page-label="Page"
                                aria-current-label="Current page" backend-sorting :default-sort-direction="defaultSortOrder" :default-sort="[sortField, sortOrder]" @sort="onSort">

                                <b-table-column field="id" label="ID" sortable v-slot="props">
                                    {{ props.row.id }}
                                </b-table-column>

                                <b-table-column label="Evaluación" v-slot="props">
                                    {{ props.row.evaluacion_tipo.nombre }}
                                </b-table-column>

                                <b-table-column field="estado" label="Estado" sortable v-slot="props">
                                    <span v-html="getEstado(props.row.estado)" />
                                </b-table-column>

                                <b-table-column field="asignatura_id" label="Asignatura" sortable v-slot="props">
                                    <span :class="'tag is-' + props.row.asignatura.imagen + ''">{{ props.row.asignatura.nombre }}</span>
                                </b-table-column>
                                
                                <b-table-column field="prueba_tipo_id" label="Tipo" sortable v-slot="props">
                                    {{props.row.prueba_tipo.nombre}}
                                </b-table-column>
                                <b-table-column field="prueba_etapa_id" label="Etapa" sortable v-slot="props">
                                    {{props.row.prueba_etapa.nombre}}
                                </b-table-column>


                                <b-table-column label="Preguntas" v-slot="props">
                                    {{ (props.row.preguntas).length }}
                                </b-table-column>

                                <b-table-column field="nivel_id" label="Nivel" sortable v-slot="props" >
                                    {{ props.row.nivel.nombre }}
                                </b-table-column>

                                <b-table-column label="Acciones" v-slot="props">
                                    <div class="buttons">
                                        <b-button v-if="props.row.estado==1" icon-left="file-pdf" icon-pack="fas" type="is-small" outlined @click="pdf(props.row.id)">Descargar</b-button>

                                    </div>
                                </b-table-column>
                                <b-table-column label="Eliminar" v-slot="props">
                                    <div class="buttons">
                                    
                                        <b-button  icon-left="trash" icon-pack="fas" type="is-small is-danger " outlined @click="eliminar(props.row.id)"></b-button>

                                    </div>
                                </b-table-column>

                                <template slot="empty">
                                    <section class="section">
                                        <div class="content has-text-grey has-text-centered">
                                            <p>No se encontraron evaluaciones</p>
                                        </div>
                                    </section>
                                </template>
                            </b-table>
                        </div>
                    </div>
                </b-tab-item>
            
            </b-tabs>
        </section>
    </div>
</div>
</template>
<script>
import axios from 'axios'
import moment from 'moment'

export default {
    name: "EvaluacionesMisEvaluaciones",
    metaInfo: {
        title: 'Mis evaluaciones'
    },
    data() {
        return {
            id: null,
            data: [],
            loading: false,
            total: 0,
            sortField: 'id',
            sortOrder: 'desc',
            defaultSortOrder: 'desc',
            page: 1,
            perPage: 20,
            asignaturas: [],
            niveles: [],
            asignatura: null,
            nivel: null,
            search_fechas: null,
            prueba:{
                prueba_tipo : [],
                prueba_etapa : [],
                asignatura : [],
                nivel : [],
            }
            
        }
    },
    methods: {
        habilitar(){
            this.$buefy.dialog.confirm({
                title: 'habilitar evaluacion',
                message: '¿Estás seguro que deseas habilitar la evaluacion?',
                confirmText: 'habilitar',
                type: 'is-success',
                hasIcon: true,
                onConfirm: () => {
                    axios.post('/api/pruebas/habilitar_prueba', {
                        prueba: this.id
                    }).then((res) => {
                        if (res.data) {
                            this.$buefy.toast.open({
                                message: 'evaluacion habilitada correctamente',
                                type: 'is-success'
                            })
                            this.buscar()
                        }
                    }).catch((err) => {
                        throw err
                    })
                }
            })

        },
        desabilitar(){
                this.$buefy.dialog.confirm({
                title: 'desabilitar evaluacion',
                message: '¿Estás seguro que deseas desabilitar la evaluacion?',
                confirmText: 'desabilitar',
                type: 'is-danger',
                hasIcon: true,
                onConfirm: () => {
                    axios.post('/api/pruebas/desabilitar_prueba', {
                        prueba: this.id
                    }).then((res) => {
                        if (res.data) {
                            this.$buefy.toast.open({
                                message: 'evaluacion desabilitada correctamente',
                                type: 'is-success'
                            })
                            this.buscar()

                        }
                    }).catch((err) => {
                        throw err
                    })
                }
            })
            this.buscar()
        },
        pdf(prueba) {
            window.open(process.env.VUE_APP_API_URL + '/prueba/' + prueba);
        },
        getEstado(args){
            if (args == 0){
                return '<span class="tag is-light">Borrador</span>';
            }
            if (args == 1){
                return '<span class="tag is-success">Cerrada</span>';
            }
        },
        getDate(date) {
            return moment(date).locale('es').format('LLL')
        },
        formatter(d) {
            return d[0].toLocaleDateString('es-CL', {
                year: 'numeric',
                month: 'numeric',
                day: 'numeric'
            }) + ' - ' + d[1].toLocaleDateString('es-CL', {
                year: 'numeric',
                month: 'numeric',
                day: 'numeric'
            })
        },
        buscar(){
             let params = {
                        prueba : this.id 
                    }
                    axios.post('/api/pruebas/get', params)
                        .then(response => {    
                             this.prueba = response.data;                    
                           
                        })
                        .catch(error => {                            
                            throw error
                        });
        },
        eliminar(id) {
            this.$buefy.dialog.confirm({
                title: 'Eliminar evaluacion',
                message: '¿Estás seguro que deseas eliminar la evaluacion?',
                confirmText: 'Eliminar',
                type: 'is-danger',
                hasIcon: true,
                onConfirm: () => {
                    axios.post('/api/pruebas/eliminar', {
                        prueba: id
                    }).then((res) => {
                        if (res.data) {
                            this.$buefy.toast.open({
                                message: 'evaluacion eliminada correctamente',
                                type: 'is-success'
                            })
                            this.loadAsyncData()
                        }
                    }).catch((err) => {
                        throw err
                    })
                }
            })
        },
        formatDateTime(fecha) {
            return moment(fecha).locale('es').format('DD-MM-YYYY, h:mm:ss a');
        },
        // formatDate(fecha) {
        //     return moment(fecha).locale('es').format('LL');
        // },
        getAsignaturas() {
            axios.get('/api/asignaturas/select').then((res) => {
                res.data.forEach((item) => {
                    this.asignaturas.push(item.asignatura)
                })
            }).catch((err) => {
                throw err
            })
        },
        getNiveles() {
            axios.get('/api/niveles/get').then((res) => {
                this.niveles = res.data
            }).catch((err) => {
                throw err
            })
        },
        /*
         * Load async data
         */
        loadAsyncData() {
            const params = [
                `sort_by=${this.sortField}`,
                `sort_order=${this.sortOrder}`,
                `page=${this.page}`,
                `asignatura=${this.asignatura}`,
                `nivel=${this.nivel}`,
            ].join('&')

            this.loading = true
            axios.get(`/api/pruebas/creadas/list/all?${params}`)
                .then(({
                    data
                }) => {
                    // api.themoviedb.org manage max 1000 pages
                    this.data = []
                    let currentTotal = data.total
                    if (data.total / this.perPage > 1000) {
                        currentTotal = this.perPage * 1000
                    }
                    this.total = currentTotal
                    data.data.forEach((item) => {
                        this.data.push(item)
                    })
                    this.loading = false
                })
                .catch((error) => {
                    this.data = []
                    this.total = 0
                    this.loading = false
                    throw error
                })
        },
        /*
         * Handle page-change event
         */
        onPageChange(page) {
            this.page = page
            this.loadAsyncData()
        },
        /*
         * Handle sort event
         */
        onSort(field, order) {
            this.sortField = field
            this.sortOrder = order
            this.loadAsyncData()
        },
    },
    mounted() {
        this.loadAsyncData()
        this.getAsignaturas()
        this.getNiveles()
    }
}
</script>
