<template>
  <div class="">
    <b-navbar class="has-shadow">
      <template slot="brand">
        <b-navbar-item tag="router-link" :to="{ path: '/' }">
          <!-- <img src="/favicon.png" alt="Aeduc"> -->
        </b-navbar-item>
      </template>
      <template slot="start">
        <b-navbar-item tag="router-link" to="/">
          Home
        </b-navbar-item>
        <b-navbar-dropdown label="Maestras">
          <b-navbar-item tag="router-link" to="/usuarios">
            <b-icon icon="users" pack="fas"></b-icon><span>Usuarios</span>
          </b-navbar-item>
          <b-navbar-item tag="router-link" to="/colegios">
            <b-icon icon="school" pack="fas"></b-icon><span>Colegios</span>
          </b-navbar-item>
          <b-navbar-item tag="router-link" to="/formatoaeduc">
            <b-icon icon="users" pack="fas"></b-icon><span>Formato Aeduc</span>
          </b-navbar-item>
        </b-navbar-dropdown>
        <b-navbar-dropdown label="Pruebas">
          <b-navbar-item tag="router-link" to="/pruebas">
            <b-icon icon="check" pack="fas"></b-icon><span>Pruebas</span>
          </b-navbar-item>
        </b-navbar-dropdown>
        <b-navbar-dropdown label="subidor de preguntas ">
          <b-navbar-item tag="router-link" to="/preguntas">
            <b-icon icon="check" pack="fas"></b-icon><span>Preguntas</span>
          </b-navbar-item>
          <b-navbar-item tag="router-link" to="/preguntas/repetidas">
            <b-icon icon="check" pack="fas"></b-icon
            ><span>Preguntas repetidas</span>
          </b-navbar-item>
          <b-navbar-item tag="router-link" to="/preguntas/modificar">
            <b-icon icon="check" pack="fas"></b-icon
            ><span>Modificar preguntas</span>
          </b-navbar-item>
          <b-navbar-item tag="router-link" to="/preguntas/parcialarray">
            <b-icon icon="check" pack="fas"></b-icon
            ><span>Preguntas Array IDs</span>
          </b-navbar-item>
        </b-navbar-dropdown>
        <b-navbar-dropdown label="actualizacion y verificacion (new)">
          <b-navbar-item tag="router-link" to="/revisar/alumnos">
            <b-icon icon="check" pack="fas"></b-icon
            ><span>revisar alumnos </span>
          </b-navbar-item>
        </b-navbar-dropdown>

        <b-navbar-item tag="router-link" to="/cargar/objetivos2023">
          <span>Cargar objetivos 2023 </span>
        </b-navbar-item>
      </template>

      <template slot="end" v-if="authenticated">
        <b-navbar-dropdown :label="user.name">
          <!-- <b-navbar-item href="/cambiar/password">
                                    Cambiar contraseña
                                </b-navbar-item> -->
          <b-navbar-item href="#" v-on:click="logout()">
            Cerrar sesión
          </b-navbar-item>
        </b-navbar-dropdown>
      </template>
    </b-navbar>
    <section class="section">
      <router-view />
    </section>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  computed: {
    ...mapGetters({
      authenticated: "auth/authenticated",
      user: "auth/user"
    })
  },

  methods: {
    ...mapActions({
      signOutAction: "auth/signOut"
    }),

    async logout() {
      await this.signOutAction();

      location.href = process.env.VUE_APP_LOGIN_URL;
    }
  }
};
</script>
