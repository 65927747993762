<template>
<div class="container" style="margin-top:-35px">
    <div class="box">
        <div class="columns">
            <div class="column">
                <nav class="breadcrumb" aria-label="breadcrumbs">
                    <ul>
                        <li>
                            <router-link to="/">Home</router-link>
                        </li>
                        <li>
                            <router-link to="/colegios">Colegios</router-link>
                        </li>
                        <li class="is-active" v-if="id"><a href="#" aria-current="page">Editar</a></li>
                        <li class="is-active" v-else><a href="#" aria-current="page">Crear</a></li>
                    </ul>
                </nav>
            </div>
        </div>
    </div>

    <div class="box">
        <div class="columns">
            <div class="column">
                <h1 class="title">Colegios</h1>
                <p>Completa el formulario para crear o editar un colegio</p>
            </div>
        </div>

        <div class="columns">
            <div class="column">
                <b-field label="Nombres">
                    <b-input v-model="nombre" ref="nombre" placeholder="Ingrese el nombre del colegio"></b-input>
                </b-field>
                <b-field label="RBD">
                    <b-input v-model="rbd" ref="rbd" type="number" placeholder="Ingrese el RBD del colegio (sin dígito verificador)"></b-input>
                </b-field>
                <b-field label="Comunas">
                    <b-select v-model="comuna_id" ref="comuna_id" expanded placeholder="Selecciona una comuna">
                        <option v-for="comuna in comunas" :value="comuna.id" :key="comuna.id">
                            {{ comuna.nombre }}
                        </option>
                    </b-select>
                </b-field>
            </div>
            <div class="column">
                <!-- <b-field label="Logo">
                    <b-input v-model="logo" ref="logo" placeholder="Ingrese el logo del colegio"></b-input>
                </b-field> -->
                <b-field label="Dirección">
                    <b-input v-model="direccion" ref="direccion" placeholder="Ingrese la dirección del colegio"></b-input>
                </b-field>
                <b-field label="Email">
                    <b-input v-model="email" ref="email" type="email" placeholder="Ingrese el email de contacto del colegio"></b-input>
                </b-field>
                <b-field label="Fono">
                    <b-input v-model="telefono" placeholder="Ingrese teléfono del colegio (opcional)" maxlength="9"></b-input>
                </b-field>
            </div>
        </div>

        <div class="columns">
            <div class="column">
                <div class="field">
                    <b-checkbox v-model="demo">Demo</b-checkbox>
                </div>
                <div class="field">
                    <b-checkbox v-model="classroom">Aula virtual</b-checkbox>
                </div>
                <div class="field">
                    <b-checkbox v-model="creador">Creador</b-checkbox>
                </div>
                <div class="field">
                    <b-checkbox v-model="recursos">Recursos digitales</b-checkbox>
                </div>
                <div class="field">
                    <b-checkbox v-model="libro_digital">Libro digital</b-checkbox>
                </div>
            </div>
        </div>

        <div class="columns">
            <div class="column">
                <b-button v-if="id" type="is-primary" @click="crear" :loading="loadingStore">Editar colegio</b-button>
                <b-button v-else type="is-primary" @click="crear" :loading="loadingStore">Crear colegio</b-button>
            </div>
        </div>

    </div>

    <div class="box" v-show="id">
        <div class="columns">
            <div class="column">
                <h2 class="subtitle">Permisos</h2>
                <p>Selecciona los permisos que tendrá el colegio</p>
            </div>
        </div>
        <div class="columns">
            <div class="column is-3">
                <b-field>
                    <b-select v-model="periodo" expanded placeholder="Selecciona un periodo">
                        <option value="2021">2021</option>
                    </b-select>
                </b-field>
            </div>
        </div>
        <div class="columns">
            <div class="column">
                <div class="field" v-for="etapa in etapas" v-bind:key="etapa.id">
                    <b-checkbox v-model="user_etapas" :native-value="etapa.id">{{etapa.nombre}}</b-checkbox>


                </div>
            </div>
        </div>
        <div class="columns">
            <div class="column">
                <!-- <b-button type="is-primary" @click="setBroker" :loading="loadingBrokers">Guardar accesos</b-button> -->

            </div>
        </div>
    </div>
    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="true"></b-loading>
</div>
</template>
<script>
import axios from "axios";
export default {
    data() {
        return {
            id: this.$route.params.id,
            rbd: null,
            nombre: null,
            comunas: [],
            comuna_id: null,
            logo: null,
            direccion: null,
            telefono: null,
            email: null,
            loadingStore: false,
            isLoading: true,
            periodo: 2021,
            etapas: [],
            user_etapas: [],
            demo: false,
            classroom: true,
            creador: true,
            recursos: true,
            libro_digital: true
        }
    },
    methods: {
        getComunas() {
            axios.get('/api/comunas/list').then((response) => {
                this.comunas = response.data;
            }).catch((error) => {
                throw error;
            })
        },
        crear() {
            let self = this

            if (!this.nombre) {
                this.$buefy.toast.open({
                    message: `Debes ingresar un nombre`,
                    position: 'is-bottom',
                    type: 'is-danger'
                })
                this.$refs.nombre.focus();
                return
            }

            if (!this.rbd) {
                this.$buefy.toast.open({
                    message: `Debes ingresar un RBD`,
                    position: 'is-bottom',
                    type: 'is-danger'
                })
                this.$refs.rbd.focus();
                return
            }

            if (!this.comuna_id) {
                this.$buefy.toast.open({
                    message: `Debes seleccionar una comuna`,
                    position: 'is-bottom',
                    type: 'is-danger'
                })
                this.$refs.comuna_id.focus();
                return
            }

            self.loadingStore = true;

            var params = {
                params: this.$data
            };

            let method = 'store';
            if (this.id) {
                method = 'update';
            }
            console.log(params);
            
            axios.post('/api/colegios/' + method, params)
                .then(response => {
                    self.loadingStore = false;
                    if (response.data.status) {
                        this.$buefy.toast.open({
                            message: response.data.message,
                            type: 'is-success'
                        })
                    } else {
                        this.$buefy.toast.open({
                            message: response.data.message,
                            position: 'is-bottom',
                            type: 'is-danger'
                        })
                    }
                })
                .catch(error => {
                    let method = 'crear';
                    
                    if (this.id) {
                        method = 'actualizar';
                        
                    }
                    this.$buefy.toast.open({
                        message: 'No se pudo '+method+' el colegio',
                        position: 'is-bottom',
                        type: 'is-danger'
                    })
                    self.loadingStore = false;
                    throw error
                });

        },
        getEtapas() {
            if (this.id) {
                axios.get('/api/pruebaetapas/get')
                    .then(response => {
                        let data = response.data
                        this.etapas = data;
                    })
                    .catch(error => {
                        throw error
                    });
            }
        },
        getColegio() {
            if (this.id) {
                let params = {
                    id: this.id,
                    periodo: this.periodo
                }
                axios.post('/api/colegios/get', params)
                    .then(response => {
                        console.log(response);
                        let data = response.data.colegio;      
                        let permisos = response.data.permisos;  
                        this.user_etapas = permisos;                    
                        this.rbd = data.rbd;
                        this.nombre = data.nombre;
                        this.comuna_id = data.comuna_id;
                        this.logo = data.logo;
                        this.direccion = data.direccion;
                        this.telefono = data.telefono;
                        this.email = data.email;
                        this.demo = data.demo;
                        this.classroom = data.classroom,
                        this.creador = data.creador,
                        this.recursos = data.recursos,
                        this.libro_digital = data.libro_digital,
                        this.isLoading = false;
                       // data.etapas.forEach(element => {
                         //   this.user_etapas.push(element.id)
                        //})
                    })
                    .catch(error => {
                        this.$buefy.toast.open({
                            message: 'No se pudo encontrar el colegio',
                            position: 'is-bottom',
                            type: 'is-danger'
                        })
                        this.isLoading = false;
                        throw error
                    });
            } else {
                this.isLoading = false;
            }
        }
    },
    mounted() {
        this.getComunas()
        this.getColegio()
        this.getEtapas()
    }
}
</script>
