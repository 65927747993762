import store from '../store/index';

export default function auth({ next }) {

    if (!store.getters['auth/user'].rol_id) {
        location.href = process.env.VUE_APP_LOGIN_URL;
        return false;
    }
    
    return next();
}
